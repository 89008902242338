body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $base-font-size;
  margin: 0 0 rem($base-font-size);
}
h1 {
	font-size: modular-scale(4);
}
h2 {
	font-size: modular-scale(3);
}
h3 {
	font-size: modular-scale(2);
}
h4 {
	font-size: modular-scale(1);
}
h5 {
	font-size: modular-scale(-1);
}
h6 {
	font-size: modular-scale(-2);
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  // transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 10%);
    text-decoration: underline;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

code {

}

blockquote {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #a5a4a4;
  font-style: italic;
  margin: 30px;
  padding: 30px;
  text-align: center;
}
