@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
// Includes Bourbon, Neat, and Bitters (thanks, thoughtbot!)
@import
  "bourbon/bourbon",
  "base/base",
  "neat/neat",
  "layout"
;

body {
  background-color: #d3d3d3e0 !important;
}

section.post-excerpt p, article.post-content p {
  text-shadow: 1px 1px 1px #ffffffca;
}
